.divider{
    width: 100%;
    height: 1px;
    flex-shrink: 0; 
    background: var(--neutral-400, #CED4DA);
}

.btn-sing-in{
    display: flex;
    width: 283px;
    padding: 9.5px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    background: var(--button, #493B43);
    color: #FFF;
    text-align: center;

    /* HelveticaNueue-Regular/Sub01 */
    font-family: HelveticaNeue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}