/* <div className="payment-list"> */
/* <div className="payment-item"> */
.payment-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.payment-list > .payment-item {
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 16px 12px;
    display: flex;
    gap: 10px;
    transition: 0.3s;
}

.payment-list > .payment-item > p {
    color: #343A40;
    font-size: 16px;
}

.payment-list > .payment-item:hover {
    background-color: #E9ECEF;
}
.payment-list > .payment-item.active {
    background-color: #E9ECEF;
}




@media only screen and (max-width: 1024px) {
    .payment-list > .payment-item > p {
        font-size: 14px;
    }
}