/* STEP 2 START*/
.btn-add-treatment {
    position: relative;
    border-radius: 5px;
    border: 0.5px solid #DDB05B;
    background: #FFF;
    padding: 8px 16px;

    display: flex;
    align-items: center;
    gap: 10px;
    > .add-icon {
        background: url("../images/svg/add-treatment-plus.svg") no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center, 50%, 50%;
        width: 20px;
        height: 20px;

    }
}
.btn-add-treatment:hover {
    background: #FFFBF5;
    transition: background-color 0.3s;
}
.btn-add-treatment:active {
    background: #F9EEDD;
}
/* .btn-add-treatment::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 16px;
    background: url("../images/svg/add-treatment-plus.svg") no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center, 50%, 50%;
} */

.btn-add-guest {
    position: relative;
    border-radius: 100px;
    border: 1px solid #493B43;
    background: #FFF;
    padding: 12px 20px;
    padding-left: 56px;
}
.btn-add-guest:hover {
    background: #F8F9FA;
    transition: background-color 0.3s;
}
.btn-add-guest:active {
    background: #E9ECEF;
}

.btn-add-guest::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 21px;
    left: 18px;
    background: url("../images/svg/add-guest-plus.svg") no-repeat;
}

.card-bottom {
    border-top: 1px solid #E9ECEF;
    display: flex;
    padding: 16px 16px;
}

.popular-tag {
    background-color: #DDB05B;
    padding: 1px 12px;
    border-radius: 4px;
}

.arr-right {
    position: absolute;
    width: 10px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}
.arr-right.active{
    transform: translateY(-50%) rotate(-90deg);
}
/* pt-[36px] md:pt-[72px] pb-[250px] md:pb-[72px] */
.bk-container {
    padding-top: 72px;
    padding-bottom: 72px;
}
.bk-container.space-popup {
    padding-bottom: 180px;
}
.bk-container.bk-step2 {
    padding-top: 72px;
    padding-bottom: 180px;
}
.bk-container.bk-step3 {
    padding-top: 72px;
    padding-bottom: 32px;
}
.bk-container.bk-step4 {
    padding-bottom: 165px;
}
.bk-container.bk-step5 {
    padding-bottom: 120px;
}
.bk-container.ribbon {
    position: relative;
}

.bk-container.ribbon::before {
    content: "";
    position: absolute;
    background-color: pink;
    background: url("../images/booking/step2/net-ribbon.svg");
    background-repeat: repeat-x;
    background-size: contain;
    background-position: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
   
}

.stepper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        
    }
}
.stepper > div {
    position: relative;
    gap: 24px;
}
.stepper > div > p {
    position: relative;
    color: #111927;
    z-index: 1;
}
.stepper > div > p > span {
    position: relative;
    color: #CED4DA;
    font-size: 20px;
}
.stepper > div.pass > p > span {
    position: relative;
    color: #FFF;
    font-weight: 500;
}
.stepper > div.active > p > span {
    position: relative;
    color: #DDB05B;
    font-weight: 500;
}

.stepper > div > p::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);

    content: "";
    display: block;
    z-index: 0;

    border: 2px solid #CED4DA;
    background: #FFF;

    width: 32px;
    height: 32px;
}
.stepper > div.active > p::before {
    border: 2px solid #DDB05B;
}
.stepper > div.pass > p::before {
    border: 2px solid #DDB05B;
    background: #DDB05B;
}

.stepper > div::after {
    content: "";
    display: block;
    position: absolute;
    top: 11px;
    right: -42px;
    width: 100%;
    height: 8px;
    background-color: #CED4DA;
}
.stepper > div.pass::after {
    background-color: #E5CEA4;

}

.stepper > div:last-child::after {
   display: none;
}


.stepper > div > span {
    color: #CED4DA;
    font-size: 16px;
}
.stepper > div.active > span {
    color: #DDB05B;
    font-weight: 500;
}
.stepper > div.pass > span {
    color: #DDB05B;
    font-weight: 400;
}

@media (max-width: 768px) {
    .stepper {
        width: 90%;
        zoom: 0.8;
    }
    .stepper > div{
        gap: 12px;
    }
    .stepper > div::after {
        top: 13px;
        height: 4px;
    }

    .stepper > div > p > span {
        font-size: 16px;
        line-height: 190%;
    }

    .stepper > div > span {
        font-size: 12px;
    }
}

@media (max-width: 1025px) {

    .bk-container.ribbon::before {
        height: 32px;
    }
    .bk-container {
        padding-top: 36px;
        padding-bottom: 120px;
    }
    .bk-container.space-popup {
        padding-top: 36px;
        padding-bottom: 120px;
    }
    .bk-container.bk-step2 {
        padding-top: 36px;
        padding-bottom: 230px;
    }
    .bk-container.bk-step3 {
        padding-top: 36px;
        padding-bottom: 221px;
    }
    .btn-add-treatment {
        padding: 8px 8px;
    }
    .btn-add-guest {
        /* padding: 0px 12px; */
        padding-left: 40px;
        /* border: 1px solid transparent; */
    }
    .btn-add-guest::before {
        transform: scale(0.6);
        left: 6px;
        background: url("../images/svg/add-guest-plus-mobile.svg") no-repeat;
    }
}
/* STEP 2 END*/

/* STEP 3 */
/* time-box start */
.time-box {
    /* background-color: pink; */
    cursor: pointer;
    /* padding: 18px 62px; */
    border-radius: 4px;
    border: 1px solid #DEE2E6;
    height: 40px;
    min-width: 120px;
    line-height: 40px;

    color: #343A40;
    font-size: 16px;
    font-weight: 500;
    text-align: center;


    @media (max-width: 1025px) {
        /* padding: 10px 34px; */
        min-width: 96px;
        line-height: 40px;
        height: 40px;
        font-size: 14px;
    }
}
.time-box:hover {
    /* background-color: pink; */
    border-radius: 4px;
    background: #DDB05B;
}
.time-box.active {
    background: #DDB05B;
}

.time-box.active {
    background: #DDB05B;
    color: #FFF;
}

.time-box:hover {
    color: #FFF
}
/* time-box end */


.box-bk-condition {
    margin-top: 92px;
    background: #FFF;
    min-height: 70vh;
    padding: 36px 50px;
    margin-bottom: 90px;
}








/* Sprint 4 */

.booking-control {
    width: 506px;
    min-height: 382px;
    border-radius: 10px;
    /* border: 1px solid #DEE2E6; */
    /* padding: 24px 0px; */
    display: flex;
    flex-direction: column;

    > .bgk-container {
        /* background: pink; */
        width: 100%;
        /* max-width: 400px; */
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;
        @media (max-width: 1025px) {
            gap: 32px;
        }
    }

    @media (max-width: 1025px) {
        width: 100%;
        /* padding: 24px 14px; */
        max-width: 600px;
        margin: auto;
    }
}

h5.bk-title-box {
    font-size: 20px;
    font-weight: 500;
    color: #111927;
    @media (max-width: 1025px) {
        font-size: 14px;
    }
}

.absolute-y-center {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @media (max-width: 1025px) {
       display: none !important;
       position: relative;
    }
}

.call-icon {
    /* background: palevioletred; */
    width: 40px;
    height: 40px;
    border: 1px solid #649591;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    > img {
        width: 18px;
        height: 18px;
    }
}


.not-found-booking {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    padding: 36px 0px;
    min-height: 0px;
    @media (max-width: 1025px) {
        min-height: calc(100vh - 198px);
    }
    /* w-full max-w-[68px] md:max-w-[80px] mx-auto */
    > img {
        width: 100%;
        max-width: 80px;
        @media (max-width: 1025px) {
            max-width: 68px;
        }
    }
    > .con0 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @media (max-width: 1025px) {
            gap: 32px;
        }
    }
}


/* STEP 1 */
/* หัวด้านบน */
.title-sp1 {
    position: relative;
    background-color: pink;
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;


    background: url("../images/booking/step1/title-bg-dk.png") 50% 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 200% 77px;
   
}
.title-sp1::before {
    position: absolute;
    content: "";
    border-radius: 10px;
    z-index: 0;
    top: 0;
    background: url("../images/booking/step1/title-bg-dk.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 0px;
    left: 0;
    width: 100px;
    height: 77px;
}
.title-sp1::after {
    position: absolute;
    content: "";
    border-radius: 10px;
    z-index: 0;
    top: 0;
    background: url("../images/booking/step1/title-bg-dk.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0px;
    right: 0;
    width: 100px;
    height: 77px;
}

@media (max-width: 1025px) {
    .title-sp1 {
        background: url("../images/booking/step1/title-bg-mb.png")  50% 0px;
        height: 65px;
        background-size: 200% 65px;
    }
    .title-sp1::before {
        background: url("../images/booking/step1/title-bg-mb.png");
        width: 100px;
        height: 65px;
        background-size: cover;
        left: 0;
        background-position: 0% 0px;
    }
    .title-sp1::after {
        background: url("../images/booking/step1/title-bg-mb.png");
        background-size: cover;
        width: 100px;
        height: 65px;
        right: 0;
        background-position: 100% 0px;
    }
}

/* หัวด้านบน */

/* Step 2 */
.item-treatment {
    position: relative;
    border-radius: 5px;
    background: #F8F8F8;
    padding: 14px 8px;
    padding-left: 48px;
    align-items: center;
    gap: 10px;

    display: flex;
    align-items: center;
    @media (max-width: 1025px) {
        padding: 12px 8px;
        padding-left: 32px;
    }
    > button.t-icon {
        position: absolute;
        left: 12px;
        width: 20px;
        height: 20px;
        background: url("../images/booking/step2/delete-0.svg") no-repeat ;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center, 50%, 50%;
        @media (max-width: 1025px) {
            width: 12px;
            height: 12px;
        }
    }
    > .it-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 7px;
        width: 100%;
        > p {
            font-size: 16px;
            color: #343A40;
            @media (max-width: 1025px) {
                font-size: 14px;
                /* white-space: nowrap; */
                /* overflow: hidden; */
                /* text-overflow: ellipsis; */
            }
        }
        > p:first-child {
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/* images/icon-delete-circle.png */


.dt-column {
    display: flex;
    column-gap: 48px;
    position: relative;
    padding: 24px 0px;
    border-top: 1px solid #DEE2E6;
    border-bottom: 1px solid #DEE2E6;
    margin: 32px 0px;
}
.dt-column::before {
    content: "";
    display: block;
    background-color: #DEE2E6;
    width: 1px;
    height: calc(100% - 48px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.dt-column aside {
    width: 50%;
}

@media (max-width: 1025px) {
    .dt-column {
        flex-direction: column;
    }
    .dt-column::before {
        display: none;
    }
    .dt-column aside {
        width: 100%;
    }
    .dt-column aside:first-child {
        border-bottom: 1px solid #DEE2E6;
        margin-bottom: 24px;
    }

    .px-full-mobile {
        padding-left: 0px;
        padding-right: 0px;

    }
    
}


.sp4-form {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #DEE2E6;
    @media (max-width: 1025px) {
        gap: 8px;
    }
}

.sp4-form > .sp4-row {
    display: flex;
    align-items: center;
}
.sp4-form > .sp4-row > p:first-child {
    width: 80px;
}

.sp4-form > .group {
    display: flex;
    @media (max-width: 1025px) {
        flex-direction: column;
        gap: 8px;
    }

}
.sp4-form > .group > .sp4-row {
    display: flex;
    align-items: center;
    flex: 1;
}
.sp4-form > .group > .sp4-row:first-child {
    margin-right: 12px;
    @media (max-width: 1025px) {
        margin-right: 0px;
    }
}

.sp4-form > .group > .sp4-row > p:first-child {
    width: 93px;
    @media (max-width: 1025px) {
        width: 80px;
    }
}


.sp4-detail {
    padding: 24px 16px;
    border-bottom: 1px solid #DEE2E6;
    @media (max-width: 1025px) {
        padding: 16px;
    }
}

.sp4-detail > .sp4-con {
    margin-top: 16px;
    display: grid;  
    grid-template-columns: repeat(2,1fr);
    gap: 8px;
    @media (max-width: 1025px) {
        grid-template-columns: repeat(2,1fr);
    }
}


.head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px 16px 16px; */
}

.sw-fn {
    background: url("../images/booking/step4/a-off.svg") no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center, 50%, 50%;
    width: 12px;
    height: 8px;
    cursor: pointer;
    &.off {
        background: url("../images/booking/step4/a-off.svg") no-repeat;
    }
    &.on {
        background: url("../images/booking/step4/a-on.svg") no-repeat;
    }
}
.tb-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

/* Step4 */
.sp4-table {
	 display: flex;
	 flex-direction: column;
	 background: #f8f9fa;
	 padding: 8px 24px;
	 gap: 4px;
}
 .sp4-table > h5 {
	 color: #212529;
	 font-size: 16px;
	 font-weight: 500;
}
 @media (max-width: 1025px) {
	 .sp4-table > h5 {
		 font-size: 12px;
	}
}
 .sp4-table > div.detail {
	 display: grid;
     grid-template-columns: 1fr 1fr;
}
 .sp4-table > div.detail > h6 {
	 color: #212529;
	 font-size: 16px;
}
 @media (max-width: 1025px) {
	 .sp4-table > div.detail > h6 {
		 font-size: 12px;
	}
}
 .sp4-table > div.detail > div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    text-align: right;
}
 .sp4-table > div.detail > div > p {
	 color: #212529;
	 font-size: 16px;
}
 @media (max-width: 1025px) {
	 .sp4-table > div.detail > div > p {
		 font-size: 12px;
	}
}


/* Step5 */
/* flex flex-col gap-[12px] pb-[] */
.sp-box0 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid #DEE2E6;
    padding-bottom: 16px;
    /* background: pink; */
}

.sp-box1 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid #DEE2E6;
    padding-top: 16px;
    padding-bottom: 24px;
    /* background: pink; */
}


.sp-box1 > .b0 {
    background-color: #F8F9FA;
    padding: 12px 16px;
    display: flex;
    gap: 4px;
    justify-content: space-between;
}
/* flex flex-col gap-[4px] py-[12px] px-[16px] */

.sp-box2 {
    border-bottom: 1px solid #DEE2E6;
    padding: 16px 0px;
    /* background: pink; */
}

.sp-box3 {
    display: flex;
    gap: 8px;
    width: 100%;
    padding: 8px 0px 16px;
    flex-direction: column;
}


.listStyle-s5-0 {
    margin-left: 22px;
    list-style: decimal;
    @media (max-width: 1025px) {
        margin-left: 18px;
        list-style: decimal;
        /* list-style: disc; */
    }
}

.listStyle-s5-1 {
    list-style: disc;
    margin-left: 22px;
    @media (max-width: 1025px) {
        margin-left: 22px;
        list-style: disc;
    }
}
.listStyle-s5-none {
    list-style: none;
    margin-left: 22px;
    @media (max-width: 1025px) {
        margin-left: 22px;
        list-style: none;
    }
}


.booking-tag.checked-in {
    border: 1px solid #0D962E;
    > p {
        color: #0D962E;
    }
}
.booking-tag.cancelled {
    border: 1px solid #A30000;
    > p {
        color: #A30000;
    }
}

.treament-img {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 2fr;
    @media (max-width: 1025px) {
        grid-template-columns: 1fr;
        /* gap: 0px; */
        /* flex-direction: column; */
    }
    
}
.treament-img > img {
    /* height: 100%;
    width: 30%;
    object-fit: contain;
    @media (max-width: 1025px) {
        width: 100%;
    } */
}

.popup-setting-space {
    padding: 40px 0px 40px;
    @media (max-width: 1025px) {
        padding: 0px 0px 40px;
    }
}

.popup-booking-space {
    padding: 40px 0px 40px;
    @media (max-width: 1025px) {
        padding: 0px 0px 100px;
    }
}