

button.btn-primary:disabled, button.btn-primary[disabled]{
  border: 1px solid #E9ECEF !important;
  background-color: #E9ECEF !important;
  color: #ADB5BD;
}

button.btn-primary {
  background-color: #493B43; 
  transition: background-color 0.3s;
}

button.btn-primary:hover {
  background-color: #73636C; 
}

button.btn-primary:active {
  background-color: #493B43;
}

button.btn-social {
  background-color: #FFFFFF; 
  transition: background-color 0.3s;
}

button.btn-social:hover {
  background-color: #F8F9FA;
}

button.btn-social:active {
  background-color: #E9ECEF;
}

.MuiInputBase-formControl:hover > fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #DDB05B !important;
}
.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid #DDB05B !important;
}