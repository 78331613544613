body {
  background: rgb(204, 204, 204);
}
page {
  position: relative;
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}
page[size="A3"] {
  width: 29.7cm;
  height: 42cm;
}
page[size="A3"][layout="landscape"] {
  width: 42cm;
  height: 29.7cm;
}
page[size="A5"] {
  width: 14.8cm;
  height: 21cm;
}
page[size="A5"][layout="landscape"] {
  width: 21cm;
  height: 14.8cm;
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}
@page {
  /* size: auto; */
}

@page {
  orphans: 4;
  widows: 2;
  /* size: auto; */
}

@page {
  orphans: 4;
  widows: 2;
  size: A4 landscape;
  /* margin-left: 2cm;
  margin-top: 2cm;
  margin-right: 2cm;
  margin-bottom: 2cm; */
  size: auto;
  /* size: "landscape"; */
}

.page {
  width: 21cm;
  /* height: 29.65cm; */
  height: 27.8cm;
  padding-left: 2cm;
  padding-top: 2cm;
  padding-right: 2cm;
  padding-bottom: 2cm;
}

.page {
  margin: 1cm auto;
  position: relative;
  background: #fff;
  box-shadow: 0 4px 5px rgba(75,75,75,.2);
  outline: 0
}

div.page-break {
  page-break-after: always
}

@media screen {
  div.page-break:before {
      content: "";
      display: block;
      height: .8cm;
      margin-left: .5cm;
      margin-right: .5cm;
      background-color: #fff;
      box-shadow: 0 6px 5px rgba(75,75,75,.2)
  }

  div.page-break {
      display: block;
      height: 1.8cm;
      margin: 1.2cm -2.5cm 2cm;
      background: #eee
  }
}


.page.no-padding {
  padding-left: 0cm;
  padding-top: 0cm;
  padding-right: 0cm;
  padding-bottom: 0cm;
}

.page.lanspace {
  width: 27.9cm;
  height: 20.95cm;
}

.page.lanspace.no-padding {
  padding-left: 0cm;
  padding-top: 0cm;
  padding-right: 0cm;
  padding-bottom: 0cm;
}
.low-padding {
  padding-left: 0.6cm;
  padding-top: 0.6cm;
  padding-right: 0.6cm;
  padding-bottom: 0.6cm;
}
.no0-padding {
  padding-left: 0cm;
  padding-top: 0cm;
  padding-right: 0cm;
  padding-bottom: 0cm;
}
.print-btn0 {
  position: absolute;
  /* top: 20px; */
  right: 20px;
}


@media print {
  html,
  body {
    background-color: #fff;
  }
  @page {
    margin: 0;
    background-color: red;
    box-shadow: 0;
  }
  .page {
    width: initial !important;
    min-height: initial !important;
    margin: 0 !important;
    padding: 0 !important;
    border: initial !important;
    border-radius: initial !important;
    background: initial !important;
    box-shadow: initial !important;

    page-break-after: always;
  }
  .print-btn0 {
    position: absolute;
    display: none;
    width: 150px;
  }
  .ReDocument {
    position: absolute;
    display: none;
  }
}



.print-table {
  outline: 1px solid #000 important;
  width: 100%;
}

.print-table, th, td {
  border: 1px solid #E3E3E3;
  border-collapse: collapse;
  padding: 2px 4px;
}
.print-table > tr > td.t {
  background-color: #EFEFEF;
  font-weight: 500;
  width: 140px;
  -webkit-print-color-adjust: exact; 
}
.print-table > tr > td {
  width: 180px;
}
.print-table > tr > td.v {
  width: fit-content;
}


.print-signature {
  position: absolute;
  right: 1cm;
  bottom: 0.5cm;
  text-align: left;
}
.print-signature  > .customer-sig {
  margin-bottom: 0px;
}
.print-signature  > .box-sig {
  width: 200px;
  height: 55px;
  border: 1px solid #000;
}
.print-signature  > .date-sig {
  margin-top: 8px;
}

.print-qrcode {
  position: absolute;
  left: 1cm;
  bottom: 0.5cm;
  text-align: left;
}
.print-qrcode  > .customer-sig {
  margin-bottom: 0px;
}
.print-qrcode  > .box-sig {
  width: 200px;
  height: 55px;
  border: 1px solid #000;
}
.print-qrcode  > .date-sig {
  margin-top: 8px;
}