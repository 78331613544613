.top-logo {
    display: flex;
    /* padding: 0px 96px; */
    align-items: center;
    height: 100px;
}

@media  screen and (max-width: 1025px) {

    .top-logo {
        align-items: center;
        /* padding: 0px 10px; */
        height: 60px;
    }

}