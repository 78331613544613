/* Tabs */

.header-nav {
    position: fixed;
    z-index: 99;
    padding: 0px;
    width: 100%;
}
.Mui-selected {
    color: #343A40 !important;
}

.MuiTabs-indicator {
    background: #DDB05B !important;
}


/* Button */
button.btn {
    border: 0;
    border-radius: 50rem;
    padding-left: .5rem;
    padding-right: .5rem;
    height: 41px;
    width: 100%;
    max-width: 300px;
}

button.btn.add-treatment:disabled {
    background-color: #DEE2E6;
    border-color: #DEE2E6 !important;
}

button.btn.add-treatment:disabled .btn-label {
    color: #6C757D !important;
}

button.btn.btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

button.btn.btn-primary {
    background: #493B43;
    color: #ffffff;
}

button.btn.btn-primary:disabled {
    background: #E9ECEF;
    color: #ADB5BD;
}

button.btn.btn-secondary {
    background: #ffffff;
    border: 1px solid #6C757D;
}

button.btn.btn-gold {
    background: #E1C596;
}

button.btn.btn-third {
    background: #649591;
    color: #ffffff;
}

.frame {
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    padding: 1rem;
}

.frame-white {
    background: #ffffff;
    margin: 1rem;
    border-radius: 10px;
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #DEE2E6;
    overflow: hidden;
    width: 100%;
}

.card .card-header {
    display: flex;
    gap: 12px;
    padding: 12px 16px;
    background: linear-gradient(360deg, #463A42 -44.95%, #6F6D6E 100%);
    color: #ffffff;
    height: 43px;
    align-items: center;
    @media (max-width: 1025px) {
        height: 40px;
        gap: 4px;
    }
}

.card .card-header-white {
    display: flex;
    gap: 8px;
    padding: 8px 24px;
    background: #ffffff;
    border-bottom: 1px solid #CED4DA;
    @media (max-width: 1025px) {
        padding: 16px 16px;
    }
}

.card-header .card-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-header-icon img {
    width: 16px;
    height: 16px;
}

.card .card-body {
    background-color: #ffffff;
}

.card-body .card-row {
}

.card-body .card-line {
    border-top: 1px solid #CED4DA;
    margin-bottom: -.5rem;
}

.line {
    border-top: 1px solid #CED4DA;
}

.step {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    cursor: default;
    position: relative;
}

.step .line {
    border-top: 4px solid #CED4DA;
    position: absolute;
    top: 32%;
    left: 70%;
    width: calc(100% - 30px);
}

.step .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 12px; */
    border: 1px solid #ffffff;
    width: 32px;
    height: 32px;
    transform: rotate(45deg);
    position: inherit;
    z-index: 10;
}

.step .circle span {
    transform: rotate(-45deg);
    font-size: 16px;
    color: #CED4DA;
}

.step-active .circle span {
    font-weight: bold;
    color: #DDB05B;
}

.step-done .circle span {
    /* font-weight: bold; */
    color: #ffffff;
}

.step-active .circle {
    background: #ffffff;
    border-color: #DDB05B;
    color: #DDB05B;
}

.step-done .circle {
    background: #DDB05B;
    border: 1px solid #DDB05B;
    color: #eee;
}

.step-inactive .circle {
    background: #ffffff;
    border-color: #CED4DA;
    color: #000;
}

.step-active .label {
    color: #DDB05B;
    font-weight: bold;
}

.step-done .label {
    color: #DDB05B;
}

.step-inactive .label {
    color: #CED4DA;
}

.step-done .line {
    border-color: #E5CEA4;
}

.step-inactive .line,
.step-active .line {
    border-color: #eee;
}






/* LAYOUT หน้าแรกๆ */
.form-space {
    padding-bottom: 42px;
    @media only screen and (max-width: 1024px) {
        padding-bottom: 20px;
    }
}

.form-header-space {
    margin-top: -48px;
    @media only screen and (max-width: 1024px) {
        margin-top: -32px;
    }
}

.form-content-space {
    background-color: #ffffff; 
    padding: 48px 10% 48px;
    padding-bottom: 48px;
    border-radius: 8px;
    @media only screen and (max-width: 1024px) {
        padding: 28px 24 24px;
        border-radius: 4px;
        border: 1px solid #DEE2E6;
    }
}

.box-content-space {
    background-color: #ffffff; 
    padding: 64px 0px 64px;
    @media only screen and (max-width: 1024px) {
        padding: 24px 0px 24px;
    }
}
.box-content-space.has-footer {
    padding-bottom: 120px;
    @media only screen and (max-width: 1024px) {
        padding-bottom: 120px;
    }
}
.box-content-space.bg-gray {
    background-color: #F2F2F2;
}

.r-step {
    position: absolute;
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #E1C596;
    bottom: 0;
    padding: 0px 30px;
    line-height: 36px;
    left: 50%;
    transform: translate(-50%,50%);
    @media only screen and (max-width: 1024px) {
        height: 24px;
        line-height: 24px;
        padding: 0px 15px;
    }
}


/* bg-white w-full py-[24px] */
.footer-normal-container {
    background: #FFF;
    width: 100%;
    padding: 24px 20px;
    display: flex;
    justify-content: center;
    gap: 24px;
    @media only screen and (max-width: 1024px) {
        gap: 16px;
    }
}
.fixed-footer {
    @media only screen and (max-width: 1024px) {
        .footer-normal-container.fixed-mb {
            background-color: #FFF;
            position: fixed;
            bottom: 0;
            left: 0;
        }
        .tail-box {
            height: 89px;
            width: 100%;
        }
    }

}

.footer-normal-container.r-step1  {
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
        padding: 12px 20px 10px;
        gap: 12px;
    }
    &> div.control {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 24px;
        @media only screen and (max-width: 1024px) {
            gap: 16px;
        }
    }
}

.spece-list {
    display: flex;
    gap: 8px;
    /* background-color: pink; */
}

/* Fixed เลื่อน */
body {
    overflow-y: scroll !important; 
    /* Show scrollbars */ 
}

.txt-gray {
    color: #D0D5DD;
}

.img-crop {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 5%;
    z-index: 9999;
}
.popup-crop {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: antiquewhite;
    z-index: 999;
    left: 0;
    top: 0;
}

.reactEasyCrop_Container  {
    /* position: fixed; */
    background: black;
    /* width: 100vh; */
    /* height: 100vh; */
    /* z-index: 1; */
}