
* {
    box-sizing: border-box;
}

body{
    background: #FFF;
}

/* Style the header */
header {
    background-color: #F1F1F1;
    padding: 24px;
    text-align: center;
    font-size: 35px;
    color: white;
}

/* Create two columns/boxes that floats next to each other */
nav {
    float: left;
    width: 30%;
    height: 300px;
    /* only for demonstration, should be removed */
    background: #ccc;
    padding: 20px;
}

/* Style the list inside the menu */
nav ul {
    list-style-type: none;
    padding: 0;
}
/* Clear floats after the columns */
section::after {
    content: "";
    display: table;
    clear: both;
}


/* Responsive layout - makes the two columns/boxes stack on top of each other instead of next to each other, on small screens */
@media (max-width: 600px) {

    nav,
    article {
        width: 100%;
        height: auto;
    }
}
.translate {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
}
.List-Translate{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-register{
    border-radius: 4px;
    border: 1px solid var(--neutral-300, #DEE2E6);
    background: #FFF;
    margin: 0 5%;
}
.singup{
    border-radius: 4px 4px 0 0;
    
}

.mobile {
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
}

.Date-Picker{
    
    height: 40px;
    width: 100%;
   
}

.calendar-logo{
    float: right;
    margin-top: -25px;
    padding-right: 10px;
    
}

.btn-radio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;
   
}
.btn-next{
display: flex;
width: 100%;
padding: 9.5px 36px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 28px;
background: #3A332C;
}
.Next{
    display: flex;
    width: 100%;
    padding: 18px;
    justify-content: center;
    align-items: center;
    background: #FFF;
    flex-direction: column;
}

.btn-verify{
    display: flex;
    width: 100%;
    padding: 9.5px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    background: var(--button, #493B43);
    color: white;
    margin-top: 36px;
    margin-bottom: 36px;
}
.contact{
    width: 100%;
    height: 112px;
    flex-shrink: 0;
    background: var(--07, #01573E);

}
.bg-info{
    width: 100%;
    height: 271px;
    background: url("../images/bg-info.png");
    background-repeat: no-repeat;
    background-position-y: -110px;
    
}
.btn-continue{
    display: flex;
    width: 60%;
    padding: 9.5px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    background: var(--button, #493B43);
}

.logo-singup{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url("../../assets//images/SingupLogo.png");
    position: relative;
    border-radius: 4px 4px 0 0;
    padding: 17px 41px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 177px;
}


.text-singup{
    text-align: center;
    font-family: MinervaModern;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    background: linear-gradient(175deg, #E8D5BD 0%, #C1A078 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 
}
.text-Personal{
    color: var(--08, #DDB05B);
/* Headline/MinervaModern H2 */
    font-family: 'MinervaModern';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 32px;
    @media only screen and (max-width: 1024px) {
        font-size: 20px;
    }
}

.Logo-singin{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url("../../assets//images/SingupLogo.png");
    position: relative;
    border-radius: 4px 4px 0 0;
    padding: 17px 41px;
    background-repeat: no-repeat;
    background-size: cover;


}
.text-singin{
    text-align: center;
/* Headline/MinervaModern H1 */
    font-family: MinervaModern;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--gd-02-gold-2, linear-gradient(175deg, #E8D5BD 0%, #C1A078 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 
}

@media  screen and (max-width: 1025px) {

    .logo-singup{
        position: relative;
        padding: 17px 41px;
    }
    

}