/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 30, 2023 */



@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/en/helveticaneue_bold-webfont.eot');
    src: url('./assets/fonts/en/helveticaneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/en/helveticaneue_bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/en/helveticaneue_bold-webfont.woff') format('woff'),
         url('./assets/fonts/en/helveticaneue_bold-webfont.ttf') format('truetype'),
         url('./assets/fonts/en/helveticaneue_bold-webfont.svg#helveticaneuebold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'MinervaModern';
    src: url('./assets/fonts/en/MINERVAMODERNRegular.otf') format('truetype');
}

@font-face {
    font-family: 'Kanit Light';
    src: url('./assets/fonts/en/Kanit-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Priyati';
    src: url('./assets/fonts/th/Priyati-Regular.otf') format('truetype');
    unicode-range: U+0E00–U+0E7F;
    font-weight: 400;
}
@font-face {
    font-family: 'Priyati';
    src: url('./assets/fonts/th/Priyati-Medium.otf') format('truetype');
    unicode-range: U+0E00–U+0E7F;
    font-weight: 500;
}

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap');

* {
    font-family: 'Helvetica Neue', 'Kanit' !important;
    line-height: 1.5em;
}

input {
    font-family: 'Helvetica Neue' !important;
}

.MinervaModern {
    font-family: 'MinervaModern', 'Priyati' !important;
}

.Kanit-Light {
    font-family: 'Kanit Light', 'Kanit' !important;
}

.HelveticaNeue {
    font-family: 'Helvetica Neue', 'Kanit' !important;
}

body {
    /* background: pink; */
}




@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/en/helveticaneue-regular-webfont.eot');
    src: url('./assets/fonts/en/helveticaneue-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/en/helveticaneue-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/en/helveticaneue-regular-webfont.woff') format('woff'),
         url('./assets/fonts/en/helveticaneue-regular-webfont.ttf') format('truetype'),
         url('./assets/fonts/en/helveticaneue-regular-webfont.svg#helveticaneuelight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/en/helveticaneue-medium-webfont.eot');
    src: url('./assets/fonts/en/helveticaneue-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/en/helveticaneue-medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/en/helveticaneue-medium-webfont.woff') format('woff'),
         url('./assets/fonts/en/helveticaneue-medium-webfont.ttf') format('truetype'),
         url('./assets/fonts/en/helveticaneue-medium-webfont.svg#helvetica_neue65_medium') format('svg');
    font-weight: 500;
    font-style: normal;

}