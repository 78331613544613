
.swal2-container {
    /* background: palegreen; */
    font-family: 'Helvetica Neue' !important;
}
.swal2-popup {
    position: relative;
    padding: 24px 24px;

    border-radius: 20px;
    width: 480px !important;
    /* background: rgb(78, 127, 167) !important; */
}

.swal2-icon {
    margin-top: 30px !important;
    width: 4em !important;
    height: 4em !important;
}
div:where(.swal2-container),h2:where(.swal2-title) {
    padding: 0px !important;
}

#swal2-title {
    font-family: 'Helvetica Neue' !important;
    font-weight: bold;
    font-size: 20px;
    color: #343A40;
    line-height: 1.2em;
    margin-bottom: 8px;
    /* padding-top: 10px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 0px; */

}
div:where(.swal2-container) div:where(.swal2-popup) {
    width: fit-content !important;
}
#swal2-html-container {
    margin: 0;
}
.swal2-actions {
    margin-top: 0px;
}
.swal2-actions {
    width: 100%;
}
.swal2-actions > .swal2-styled.swal2-confirm {
    background-color: #493B43;
    min-width: 180px;
    color: #fff;
    height: 41px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
}
.sw-subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-bottom: 24px;
}
.sw-subtitle > .under-detail {
    /* position: absolute; */
    width: 100%;
    z-index: 10;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #8F9398;
    bottom: 22px;
    left: 0;

}
.swal2-shown.swal2-height-auto {
    overflow: hidden !important;
}
.sw-link {
    /* text-decoration: underline; */
    font-size: 16px;
    cursor: pointer;
    color: #493B43;
    font-weight: bold;
}

.swal2-confirm.swal2-styled {
    margin: 0 !important;
}

.swal2-footer {
    margin: 0 !important;
    padding: 0 !important;
    border: 0;
    margin-top: 16px !important;

}

.title-danger > h2{
    /* background: red; */
    color: #914C2A !important;
}

/* แก้ Focus */
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: none !important;
}

@media only screen and (max-width: 1025px) {
    
    .swal2-popup {
        padding: 35px 35px;
        max-width: 90% !important;     
    }
    #swal2-title {
        font-size: 20px;
    }

    .sw-subtitle {
        font-weight: 400;
        font-size: 16px;
        color: #4C5159;
        margin-bottom: 18px;
    }

    .swal2-actions > .swal2-styled.swal2-confirm {
        color: #fff;
        font-size: 16px;
    }
    .swal2-footer {
        margin-top: 18px !important;
        font-size: 14px;
    
    }

    .under-detail {
        font-size: 16px;
    }

}