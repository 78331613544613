

.PhoneInput {
    display: flex;
    /* background-color: pink; */
    border-radius: 8px;
    padding: 10px 12px;
    border: 1px solid #D0D5DD;
    font-size: 18px;
    width: 100%;
}
.PhoneInput > .PhoneInputCountry {
    position: relative;
    /* height: 40px; */
    /* display: flex; */
    /* width: 10%; */
}
.PhoneInput > .PhoneInputCountry > .PhoneInputCountryIcon {
    position: absolute;
    top: 0;
    right: 50px;
    width: 20px;
    /* height: 40px; */
    /* display: flex; */
    /* width: 10%; */
}
input[type="tel"] {


}