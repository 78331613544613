.cp-title {
    position: relative;
    color: #DDB05B;
    text-align: center;
    font-family: "MinervaModern" !important;
    /* font-size: clamp(30px, 3.333vw, 48px); */
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;

}

.cp-title::after {
    position: absolute;
    content: "";
    width: 156px;
    height: 2px;
    
    background: linear-gradient(90deg, rgba(221, 176, 91, 0.00) 0%, #DDB05B 51.85%, rgba(221, 176, 91, 0.00) 100%);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}


.cp-title-2 {
    position: relative;
    color: #DDB05B;
    text-align: center;
    font-family: "MinervaModern" !important;
    /* font-size: clamp(30px, 3.333vw, 48px); */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    padding-bottom: 8px;
}
.cp-title-2::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    
    background: linear-gradient(90deg, rgba(221, 176, 91, 0.00) 0%, #DDB05B 51.85%, rgba(221, 176, 91, 0.00) 100%);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cp-title-2::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    
    background: linear-gradient(90deg, rgba(221, 176, 91, 0.00) 0%, #DDB05B 51.85%, rgba(221, 176, 91, 0.00) 100%);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cp-title.head {
    /* font-size: 32px; */
    background: var(--GD02---Gold2, linear-gradient(103deg, #E8D5BD 20.74%, #C1A078 85.15%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.cp-title.head::after {
    width: 328px;
    height: 2px;
    background: linear-gradient(90deg, rgba(223, 201, 174, 0.00) 0%, #DFC9AE 47.4%, rgba(223, 201, 174, 0.00) 99.48%);
    @media only screen and (max-width: 1024px) {
        width: 156px;
    }
}

.cp-sub-title {
    color: #343A40;
    font-size: 16px;
    font-weight: 500;
    @media only screen and (max-width: 1024px) {
        font-size: 16px;
    }
}

.gird-auto {
    display: flex;
    justify-content: space-between;
    
}

.textarea {
    width: 100%;
    height: 67px;
    padding: 12px 12px;
    font-size: 16px;
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    background-color: #F8F9FA;
  }

@media screen and (max-width: 1025px) {
    .cp-title {
        font-size: 30px;
    }
    .gird-auto {
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 8px;
    }
}