.c-multi-select-dropdown {
    /* margin: 100px auto; */
    /* font-family: 'Roboto', sans-serif; */
    position: relative;
    /* width: 300px; */
    font-size: 20px;
    transition: .3s;
    /* min-width: 80px; */
  
  }
  
  .c-multi-select-dropdown:hoverx .c-multi-select-dropdown__options {
    display: block;
  }
  .c-multi-select-dropdown.active .c-multi-select-dropdown__options {
    display: block;
  }
  .c-multi-select-dropdown:hoverx .c-multi-select-dropdown__selected{
    border: solid 1px rgba(255, 255, 255, 0.5);
  }
  .c-multi-select-dropdown:hoverx .c-multi-select-dropdown__selected > svg{
    fill: rgba(255, 255, 255, 0.5);
    rotate: 180deg;
  }
  

  
  .c-multi-select-dropdown__selected {
    /* border: solid 1px #FFF; */
    border-radius: 6px;
    font-size: 20px;
    color: #3A332C;
    padding: 10px 36px 10px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  
  
  .c-multi-select-dropdown__selected > img {
    height: 18px;
    padding-left: 8px;
    padding-right: 0px;
    
  }
  .c-multi-select-dropdown.active > .c-multi-select-dropdown__selected  > img {
    transform: rotate(180deg);
    padding-left: 0px;
    padding-right: 8px;
  }
  .c-multi-select-dropdown__selected > svg {
    fill: #3A332C !important;
  }
  
  .c-multi-select-dropdown__options {
    z-index: 99;
    background: #FFF;
    display: none;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    list-style: none;
    padding-left: 0px;
    border: solid 1px #eee;
    padding: 5px 0px;
    border-radius: 4px;
  
    max-height: 250px;
    overflow-y: auto;
  }
  
  .c-multi-select-dropdown__option {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
  
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  
  .c-multi-select-dropdown__option:hoverx {
    color: #3A332C;
    background-color: #F8F8F8;
    
  }
  
  .c-multi-select-dropdown__option-checkbox {
    margin-right: 6px;
    background-color: #F8F8F8;
    fill: #F8F8F8;
    padding: 4px;
  }
  .profile-bar {
    display: flex;
    align-items: center;
  }

  .profile-bar > div >.c-multi-select-dropdown__selected {
    padding: 0px;
  }
  
  @media only screen and (max-width: 1024px) {
    .c-multi-select-dropdown__selected {
      font-size: 14px;
      padding: 6px 16px ;
      padding-left: 0px;
    }
    .c-multi-select-dropdown__option {
      padding: 8px 10px;
      cursor: pointer;
      font-size: 12px;
     
    }
  }