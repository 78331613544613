.hl-datepicker > div > .MuiInputBase-input {
    cursor: pointer;
}


/* .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
    padding: 32px 24px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 20px;
} */
.selection-hl > svg.MuiSvgIcon-root {
    /* right: 14px; */
    fill: #DDB05B;
    font-size: 45px;
}

.MuiOutlinedInput-input{
    /* height: 10px; */
    /* width: 20px; */
    min-width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

fieldset[class*="MuiOutlinedInput-notchedOutline"]{
    top: -14px;
    border-color: #D0D5DD;
    border-radius: 8px;
}
fieldset[class*="MuiOutlinedInput-notchedOutline"]:hover,
.MuiInputBase-root.MuiOutlinedInput-root:hover fieldset[class*="MuiOutlinedInput-notchedOutline"]{
    top: -14px;
    border-color: #DDB05B !important;
    border-radius: 8px;
}
.MuiSelect-select .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 50px;
}


.selection-hl  [class*="MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"].MuiSelect-select {
    line-height: 1.5em;
}

/* RADIO */
.MuiFormControlLabel-label {
    font-size: 14px !important;
    --tw-text-opacity: 1;
    color: rgb(52 58 64 / var(--tw-text-opacity));
}

.MuiInputBase-root > input[type="text"] {
    color: #343A40;
}


/* CHECKBOX */
.check-box-hl {
    display: flex;
    font-size: 10px;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}
.btn-check{
    color: #DDB05B;
    
}

.Mui-checked {
    color: #DDB05B !important;
}

/* SELECT */
.MuiMenu-list  {
    max-height: max(300px, 20vh);
}
.MuiMenu-list > li.MuiMenuItem-gutters {
    font-size: 16px;
    padding: 12px 24px; 
}
.MuiMenu-list > li.MuiMenuItem-gutters > em {
    font-style: normal !important;
}

/* RADIO */
.MuiRadio-root > span > svg.MuiSvgIcon-root {
    font-size: 24px;
    color: #E1C596;
}

/* TEXT ERR */
.MuiFormHelperText-contained.Mui-required {
    margin-left: 8px;
}
.MuiFormHelperText-root.Mui-error {
    margin-left: 8px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    border-radius: 8px;
    margin-left: 10px;
}

/* checkbok ทำเอง */
.mark-check > svg {
    width: 18px;
    height: 18px;
}

.MuiFormHelperText-root.Mui-error {
    color: #FF0000 !important;
}

/* INPUT */
.MuiInputBase-root.MuiOutlinedInput-root {
    border-color: #D0D5DD;
    border-radius: 8px;
}

.hl-tab  > .MuiTabs-scroller > div > button.MuiTab-root.Mui-selected  {
    color: #DDB05B !important;
}
.hl-tab  > .MuiTabs-scroller > div > button.MuiTab-root  {
    color: #343A40 !important;
}

.MuiPickersToolbar-root.MuiDatePickerToolbar-root {
    display: none !important;
}
      
.tab-point {
    background-color: #F8F9FA;
    cursor: pointer;
    transition: .3s;
}
.tab-point > p {
    color: #343A40;
    transition: .3s
}

.tab-point.active {
    cursor: none;
    background-color: #493B43;
    transition: .3s;
}
.tab-point.active > p {
    color: #FFFFFF;
    transition: .3s
}


/* DATE CUSTTOM */
.MuiPaper-root.MuiPaper-elevation {
    /* background-color: pink; */
    border-radius: 10px;
}
.MuiPickersArrowSwitcher-root {
    position: absolute;
    /* background-color: pink; */
    width: 100%;
    left: 0;
    /* z-index: -1; */
}
.MuiPickersArrowSwitcher-root > .MuiPickersArrowSwitcher-spacer{
    /* background-color: aqua; */
    width: 80%;
}

.MuiPickersCalendarHeader-labelContainer {
    position: absolute;
    z-index: 200;
    /* background-color: red; */
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%; */
    /* display: flex; */
    /* justify-content: center; */
    /* padding-left: 72px; */
}
.MuiPickersCalendarHeader-labelContainer > .MuiPickersFadeTransitionGroup-root {
    /* background: blue; */
    width: 100%;
    text-align: center;
}
.MuiPickersCalendarHeader-labelContainer > .MuiButtonBase-root{
    display: none;
}
.MuiPickersCalendarHeader-label {
    color: #DDB05B;
    font-weight: 500;
    font-size: 18px;
}

.MuiDayPicker-header > span{
    color: #493B43;
    font-weight: 500;
}

.MuiButtonBase-root.MuiPickersDay-root {
    /* background-color: pink; */
    color: #343A40;
    font-size: 16px;
    border-radius: 50%;
    /* line-height: 0.5em; */
    /* border: 1px solid #DDB05B; */
    /* height: 32px; */
    /* width: 32px; */
    /* margin: 2px; */
}
.MuiButtonBase-root.MuiPickersDay-root::before {
    content: "";
    position: absolute;
    /* border: 1px solid #DDB05B; */
    border-radius: 50%;
    height: 32px;
    width: 32px;
}
.hl-date-step3 .MuiButtonBase-root.MuiPickersDay-root::before {
    border: 1px solid #DDB05B;
}
.MuiPickersCalendarHeader-root {
    /* background: pink; */
    margin: 8px 4px !important;
}
.MuiButtonBase-root.MuiPickersDay-root:disabled::before {
    content: "";
    position: absolute;
    border: 0;
    border-radius: 50%;
    height: 32px;
    width: 32px;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    /* background-color: #DDB05B; */
    color: #FFFFFF !important;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: #DDB05B;
    color: #FFFFFF !important;
    border: 2px solid white;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus {
    background-color: #DDB05B;
    color: #FFFFFF !important;
}

.MuiPickersDay-today {
    border: 0px !important;
}
.MuiInputBase-input.MuiOutlinedInput-input{
    font-size: 16px;
    padding: 14px 24px;
}

.input-bookingx .MuiInputBase-input.MuiOutlinedInput-input{
    font-size: 14px;
    padding: 12px 12px;

}
.input-bookingx .selection-hl > svg.MuiSvgIcon-root {
    font-size: 32px;
}


/* Calendar */
.MuiMonthPicker-root {
    padding: 0px 10px;
}
.PrivatePickersYear-root > button.PrivatePickersYear-yearButton {
    font-size: 14px;
}
.MuiMonthPicker-root > .PrivatePickersMonth-root  {
    font-size: 14px;
    @media  screen and (max-width: 1025px) {
        margin-top: 0px;
    }
}
.MuiTypography-root.MuiTypography-h5.PrivatePickersMonth-root.Mui-selected, .PrivatePickersYear-yearButton.Mui-selected {
    background-color: #DDB05B !important;
    color: #FFF !important;
    font-size: 12px;
}
.MuiPaper-elevation24 > .MuiDialogActions-root > .MuiButtonBase-root.MuiButton-root:hover {
    background-color: #DDB05B;
    color: #FFF !important;
    
}
.MuiPaper-elevation24 > .MuiDialogActions-root > .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 400;
    border-radius: 20px;
    min-width: 80px;
}

.css-1ehaqqx-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
    display: none;
}


@media screen and (max-width: 1025px) {

    /* checkbok ทำเอง */
    .mark-check > svg {
        width: 15px;
        height: 15px;
    }


    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        border-radius: 10px;
    }

    .MuiInputBase-input.MuiOutlinedInput-input{
        font-size: 14px;
        padding: 10px 12px;
    }

    /* RADIO */
    .MuiRadio-root > span > svg.MuiSvgIcon-root{
        font-size: 16px;
        color: #E1C596;
    }


    /* SELECT */

    /* .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
        height: 15px;
        padding: 10px 12px;
        font-size: 12px;
    } */
    .MuiMenu-list > li.MuiMenuItem-gutters{
        font-size: 20px;
        padding: 4px 10px;
        /* min-height: 28px; */
        
    }

    .selection-hl > svg.MuiSvgIcon-root {
        font-size: 32px;
    }
    .MuiMenu-list > li.MuiMenuItem-gutters{
        font-size: 12px;
        /* padding: 0px; */
        min-height: 28px;
        
    }
    .MuiMenu-list > li.MuiMenuItem-gutters > em{
        font-style: normal !important;
        
    }

    /* TEXT ERR */
    .MuiFormHelperText-contained.Mui-required {
        font-size: 10px;
        margin-left: 0px;
    }
    .MuiFormHelperText-root.Mui-error {
        font-size: 10px;
        margin-left: 0px;
    }
}


/* TAB ITEM */
.MuiButtonBase-root.MuiTab-root {
    font-size: 16px;
    @media screen and (max-width: 1025px) {
        font-size: 12px;
    }
}

.PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition {
    min-height: 230px;
}

.MuiDialogActions-root {
    padding-top: 0px;
}
.MuiDialogActions-root > button{
    /* background: pink; */
    border: 1px solid rgb(229, 231, 235);
}

.hl-date-step3 .MuiCalendarOrClockPicker-root {
    display: flex;
    flex-direction: column;
}

.MuiNumberInput-input {
    background-color: pink;
    pointer-events: none;
}


.MuiInputBase-root.MuiNativeSelect-root {
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 16px;
    /* font-size: 14px; */
}
.MuiInputBase-root.MuiNativeSelect-root::before {
    border-bottom: 0px !important;
}
.MuiInputBase-root.MuiNativeSelect-root.Mui-focused {
    border-bottom: 0px;
}
.MuiInputBase-root.MuiNativeSelect-root.selection-hl > svg.MuiSvgIcon-root {
    right: 6px;
}

.MuiInputBase-root.MuiNativeSelect-root > .MuiNativeSelect-select {
    /* background-color: pink; */

    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 32px;
    padding-top: 14px;
    /* padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 32px;
    padding-top: 10px; */
}
.MuiInputBase-root.MuiNativeSelect-root > .MuiNativeSelect-select:focus {
    /* background-color: pink; */
    border-radius: 8px;
}

.MuiInputBase-root.MuiNativeSelect-root > .MuiNativeSelect-select > option {
    padding: 4px;
    height: 100px;
}
.MuiInputBase-root.MuiNativeSelect-root > .MuiNativeSelect-select > option:hover{
    background-color: green !important;
}

/* Autocomplete */
.MuiInputBase-root.MuiAutocomplete-inputRoot > input[type="text"]{
    padding: 5px 15px;
}
.MuiAutocomplete-popper > div {
    font-size: 16px;
}
.autocomplete-hl .MuiAutocomplete-endAdornment {
    top: auto;
}
.autocomplete-hl  button.MuiAutocomplete-popupIndicator > svg.MuiSvgIcon-root {
    fill: #DDB05B;
    font-size: 45px;
}

@media screen and (max-width: 1025px) {
    .MuiInputBase-root.MuiAutocomplete-inputRoot > input[type="text"]{
        padding: 2px 2px;
    }
    .MuiAutocomplete-popper > div {
        font-size: 12px;
    }
    .autocomplete-hl  button.MuiAutocomplete-popupIndicator > svg.MuiSvgIcon-root {
        font-size: 32px;
    }
}