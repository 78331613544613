/* text-[16px] md:text-[20px] font-medium text-brown-1 text-center */

.b-policy > * {
    margin-bottom: 16px;
}
.b-policy > *:last-child {
    margin-bottom:0px;
}

.b-policy > h2 {
    font-size: 20px;
    font-weight: 500;
    color: #DDB05B;
}
.b-policy > p {
    font-size: 16px;
    font-weight: 300;
    color: #000;
}
.b-policy > p > b {
    font-weight: 500;
    color: #DDB05B;
}

.b-policy > ul {
    list-style: disc;
    margin-left: 24px;
}

.b-policy > ul > li {
    font-size: 16px;
    font-weight: 300;
}

.b-policy a {
    text-decoration: underline;
}




@media only screen and (max-width: 1025px) {
    .b-policy > h2 {
        font-size: 16px;
    }
    .b-policy > p {
        font-size: 14px;
    }
    .b-policy > ul > li {
        font-size: 14px;
    }
    
    
}