.card-point {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: url("../images/BG-Card-Point.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 15vh;
    width: 50%;
}

@media (max-width: 1025px) {
    .Card-Point {
        height: 12vh;
        width: 100%;
    }
}

.Card-UpComing{
    width: 50%;
}
@media (max-width: 1025px) {
    .Card-UpComing {
        width: 100%;
    }
}

.btn-point {
    display: flex;
    height: 29px;
    padding: 5px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: var(--primary-01, #E1C596);

}

.Card-List {
    padding: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--neutral-200, #E9ECEF);
    /* DropShadow01 */
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
}

.Card-Profile1{
    padding: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--neutral-200, #E9ECEF);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
    position: relative;
}

.profile1{
    position: relative;
    width: 100%; 
    height: 100%; 
}

.imgProfile {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid #DDB05B;
    object-fit: cover;
    /* width: 15% ; */
}

.imgProfile-bar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-color: rgb(221, 176, 91);
    border-width: 2px;
    object-fit: cover;
    /* width: 15% ; */
}
.imgProfile-bor {
    border-radius: 50%;
    border-color: rgb(221, 176, 91);
    border-width: 2px;
    object-fit: cover;
}


@media (max-width: 1025px) {
    .imgProfile {
        width: 112px;
        height: 112px;
        border-radius: 50%;
        border-color: rgb(221, 176, 91);
        border-width: 4px;
        /* width: 15% ; */
    }
}

.profile-container {
    display: flex;
    justify-content: center;

    gap: 24px;
    /* background-color: pink; */
    @media (max-width: 1025px) {
        flex-direction: column;
    }

}

.profile-head {
    width: 294px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    row-gap: 32px;
    max-height: 410px;

    @media (max-width: 1025px) {
        width: 100%;
        gap: 16px;
        max-width: 600px;
        margin: auto;
        /* margin-bottom: 25px; */
    }
}

.profile-head.show-desktop {
    @media (max-width: 1025px) {
        display: none;
    }
}


.profile-control {
    width: 506px;
    min-height: 382px;
    border-radius: 10px;
    border: 1px solid #DEE2E6;
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .pc-container {
        /* background: pink; */
        width: 100%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        @media (max-width: 1025px) {
            gap: 16px;
        }
    }

    @media (max-width: 1025px) {
        width: 100%;
        padding: 24px 14px;
        max-width: 600px;
        margin: auto;
    }
}




.btn-upcoming{
    width: 100px;
    height: 25px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--secondary-04, #914C2A);
}

.DateCard {
    display: flex;
    width: 100%;
    height: 150px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--neutral-300, #DEE2E6);
    background: #FFF;
}

.DateCard-Location {
    width: 40%;
    height: 150px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: var(--gd-02-gold, linear-gradient(180deg, #E8D5BD 0%, #C1A078 100%));

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.btn-more {
    display: flex;
    width: 139px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 25px;
    color: #6C757D;
    border: 0.5px solid var(--Neutral-600, #DEE2E6);
}

@media (max-width: 1025px) {
    .DateCard{
        height: 120px;
    }
    .DateCard-Location{
        height: 120px;
    }
    .btn-more{
        font-size: 10px;
        width: 140px;
        height: 24px;
    }
}

.bg-info-profilr {
    width: 100%;
    height: 100%;
    background: url("../images/bg-info.png");
    background-repeat: no-repeat;
    background-position-y: -110px;
}

.List {
    width: 100%;
    height: 250px;
    background-color: #FFF;
}

.divider-profile {
    width: 100%;

    width: 156px;
    height: 2px;
    background: linear-gradient(90deg, rgba(227, 207, 181, 0.00) 0%, #E3CFB5 51.85%, rgba(227, 207, 181, 0.00) 100%);
}

.btn-EditProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding: 8px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 28px;
    border: 1px solid var(--button, #3A332C);
}

@media (max-width: 1025px) {
    .btn-EditProfile{
        width: 70%;
    }
}

.bg-profile-footer {
    width: 100%;
    height: 200px;
    background: url("../images/bg-profile-fotter.svg");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;

}
.bg-profile-left{
    width: 50%;
    background: url("../images/bg-profile.svg");
    background-repeat: no-repeat;
}

.bg-profile-right{
    border-width: 2px;
    border-radius: 20px;
    padding: 32px 48px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.record-view {
    display: grid;
    grid-template-columns: 50% 50%;
}

@media only screen and (max-width: 1025px) {
    .bg-profile-right{
        padding: 25px 25px;
        margin: 0 auto;
    }
  }

.bg-edit-profile{
    width: 80%;
    position: relative;
    cursor: pointer;
}

/* modal points */
.modalClose{
    width: 100%;
    height: 50px;
    padding: 9.5px 36px 9.5px 36px;
    border-radius: 28px;
    gap: 10px;
    color: white;
    background: #493B43;
    margin-top: auto;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1025px) {
    .modalClose{
        height: 30px;
        padding: 5px;
    }
  }

.logoutHover:hover{
    background-color: #222222;
    color: white;
}

.btn-list {
    position: relative;
    padding: 10px 32px;
    cursor: pointer;

}

.btn-list::before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: palevioletred;
    /* background: url("../../assets/images/arrow-list.svg") center center no-repeat; */
    background-size: auto 100%;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.btn-list::after {
    position: absolute;
    content: "";
    right: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: palevioletred;
    background: url("../../assets/images/arrow-list.svg") center center no-repeat;
    background-size: auto 100%;
    transition: all 0.3s ease;
    margin-top: 10px;
}
.btn-list.active::after {
    transform: rotate(90deg);
}

.ctEditImage {
    position: absolute;
    cursor: pointer;
    top: 70%;
    left: 70%;

    width: 30px;
    height: 30px;
    border-radius: 20px;
    background-color: #DDB05B;
}

.box-form-content-space {
    background-color: #ffffff; 
    padding: 32px 40px;
    border: 2px solid #DEE2E6;
    border-radius: 10px;
    @media only screen and (max-width: 1024px) {
        padding: 16px 24px;
    }
}

h5.title-form {
    font-size: 20px;
    font-weight: 700;
    @media only screen and (max-width: 1024px) {
        font-size: 14px;
    }
}

.form-space {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.image-editable > label {
    cursor: pointer;
}