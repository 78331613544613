.modal-base {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 12px;
    outline: none;
    /* padding: 45px 20px; */
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    max-width: 811px;
    max-height: 92vh;
}
.ReactModal__Overlay {
    z-index: 100;
}
.modal-base.size1 {
    max-width: 500px;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media only screen and (max-width: 1024px) {
        gap: 24px;
    }
    &.notpad {
        padding: 0px;
        gap: 0px;
    }
}

.modal-base.size2 {
    max-width: 50px;
    padding: 32px 16px;
    /* border: 0px; */
}
.modal-base.size3 {
    max-width: 334px;
    padding: 32px 25px;
    /* border: 0px; */
}
.modal-base.size4 {
    max-width: 400px;
    padding: 0px;
    /* border: 0px; */
}

.modal-base.size2 {
    max-width: 335px;
    @media  screen and (max-width: 1025px) {
        max-width: 610px;
    }
}

.modal-base .content-scroll {
    overflow-y: auto;
    min-height: 30vh;
    max-height: 60vh;
}

.modal-base .content-scroll::-webkit-scrollbar {
    display: none;
}

.modal-base .content-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.modal-pay {
    background: #FFF;
}

.modal-pay > .hp-header {
    display: flex;
    background: #493B43;
    justify-content: space-between;
    padding: 16px 24px;
}

.modal-pay > .hp-header > .mp-title {
    font-size: 20px;
    font-weight: 500;
    color: #FFF;
}
.modal-pay > .hp-header > .mp-close {
    cursor: pointer;
}
.modal-pay > .mp-content {
    padding: 16px 43px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.modal-pay > .mp-content > .one {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 20px;
}
.modal-pay > .mp-content > .two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    border-top: 1px solid #DEE2E6;
    padding: 24px 8px 0px;
    font-size: 16px;
}
.modal-pay > .mp-content > .two > span{
    font-weight: 500;
}
.modal-pay > .mp-content > .three {
    display: flex;
    justify-content: center;
}

.count-down-box {
    padding-bottom: 24px;
}

.pp-view-treaments {
    padding: 24px 21px;
    position: relative;
}
.pp-view-treaments > .header > .exit {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
}
.pp-view-treaments > .header {
    /* display: flex; */
    position: fixed;
    width: 100%;
    /* margin-bottom: 16px; */
    background-color: #FFF;
    top: 0px;
    left: 0px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pp-view-treaments > .header > h5 {
    /* background-color: pink; */
    margin-top: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #DDB05B;
}
.pp-view-treaments > .content {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.pp-view-treaments > .content > .item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #ADB5BD;
}
.pp-view-treaments > .content > .item:last-child {
    padding-bottom: 0px;
    border-bottom: 0px;
}
.pp-view-treaments > .content > .item > .box {
    display: grid;
    grid-template-columns: 70% 30%;
}
.pp-view-treaments > .content > .item > .box:first-child {
    grid-template-columns: 25% 75%;
}
.pp-view-treaments > .content > .item > .box:first-child > b {
    font-size: 16px;
}
.pp-view-treaments > .content > .item > .box:first-child > p {
    font-size: 16px;
    font-weight: 300;
}
.pp-view-treaments > .content > .item > .box > p {
    font-size: 14px;
}
.pp-view-treaments > .content > .item > .box > p:last-child {
    text-align: right;
}

@media only screen and (max-width: 1025px) {
    .pp-view-treaments > .content > .item > .box:first-child > p,b {
        font-size: 14px;
    }
    .pp-view-treaments > .content > .item > .box > p {
        font-size: 12px;
    }
}