.bg-membership-header {
    width: 100%;
    background: url("../images/bg-profile.svg");
    background-repeat: no-repeat;
    background-position-y: -130%;
}

.divider-membership {
    width: 156px;
    height: 2px;
    background: linear-gradient(90deg, rgba(227, 207, 181, 0.00) 0%, #E3CFB5 51.85%, rgba(227, 207, 181, 0.00) 100%);
}

.card-membership {
    position: relative;
    width: 100%;
    height: auto;
    min-width: 335px;
  
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    z-index: 1;

    background: url("../images/member-card-desktop.png") 50% 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 200% 139px;
    
}
.card-membership::before {
    position: absolute;
    content: "";
    background: url("../images/member-card-desktop.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: 0;
    top: 0;

    height: 139px;
    width: 90px;
    left: 0;
}
.card-membership::after {
    position: absolute;
    content: "";
    background: url("../images/member-card-desktop.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 10px;
    z-index: 0;
    top: 0;

    height: 139px;
    width: 90px;
    right: 0;
}

.c-mp-container {
    position: relative;
    padding: 22px 20px;

    height: 139px;
    min-width: 335px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    z-index: 20;


}



.info-membership-container {
    display: flex;
    gap: 1.5rem;
}

.info-membership-container img {
    width: 60px;
    height: 60px;
}

.info-membership-footer {
    display: flex;
    justify-content: space-between;
}

.info-membership-footer .btn-point {
    display: flex;
    height: 29px;
    padding: 5px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;
    background: var(--primary-01, #E1C596);
    font-size: 10px;
}

.item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}

.item-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    padding: 1rem;
    overflow: hidden;
}

.item-container .item-row {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.item-container .item-left {
    padding: 1rem;
    background: linear-gradient(360deg, #463A42 -44.95%, #6F6D6E 100%);
    color: #ffffff;
}

.item-container .item-right {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.item-container .item-top {
    padding: 1rem;
    border-bottom: 1px solid #DEE2E6;
}

.item-container .item-bottom {
    padding: 1rem;
}

.item-row .discount {
    font-weight: bolder;
}

.item-row .id {
    color: #6C757D;
}

.item-row .date {
    color: #6C757D;
}

.item-row .points {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: bolder;
    color: #602E20;
}

.points img {
    width: 16px;
    height: 16px;
}


.color-success {
    color: #0D962E;
}

.color-fail {
    color: #A30000;
}



.num-point {
    background: var(--GD02---Gold2, linear-gradient(103deg, #E8D5BD 20.74%, #C1A078 85.15%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.unit-point{
    background: var(--GD02---Gold2, linear-gradient(103deg, #E8D5BD 20.74%, #C1A078 85.15%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.member-ship-point-benefits-btn {
    padding: 7px 20px;
    border-radius: 32px;
    background: #E1C596;
    line-height: 1em;
    > p {
        color: #493B43;
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
        @media (min-width: 1024px) {
          font-size: 16px;
        }
    }
}