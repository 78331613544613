/* Style the footer */
footer {
    /* background-color: #F1F1F1;
    padding: 10px;
    text-align: center;
    color: white; */
}

footer.summary {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F8F9FA;
}

.summary > .box-1 {
    position: relative;
    padding: 8px 24px;
    @media (max-width: 1025px) {
        padding: 16px 4px;
    }
}

.summary > .box-1::before {
    content: "";
    display: block;
    position: absolute;
    background-color: pink;
    background: url("../images/booking/step2/net-ribbon2.svg");
    background-repeat: repeat;
    background-size: contain;
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;

    background-repeat: repeat;
    background-size: 66% auto;
    background-position: center left;
    right: 0;
    top: 0;
    width: 8%;
    height: 100%;
}
.normal > .box-1 {
    position: relative;
}
.normal > .box-1::before {
    content: "";
    display: block;
    position: absolute;
    background-color: pink;
    background: url("../images/booking/step2/net-ribbon2.svg");
    background-repeat: repeat;
    background-size: contain;
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;

    background-repeat: repeat;
    background-size: 66% auto;
    background-position: center left;
    right: 0;
    top: 0;
    width: 8%;
    height: 100%;
}

h5.icon {
    position: relative;
    padding-left: 18px;
    font-size: 14px;
}

h5.icon::before {
    content: "";
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    left: 0;
    width: 12px;
    height: 12px;
}
h5.icon[type*="guest"]::before{
    background: url("../images/svg/guest-one.svg") no-repeat;
    background-size: 100%;
}


.summary > .box-2 {
    border-top: 1px solid #E9ECEF;
   padding: 28px 25px;
   background-color: #FFF;
}

footer.treatment {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F8F9FA;
    z-index: 9999;
}

.treatment > .box-1 {
   padding: 16px 24px;
}

.treatment > .box-2 {
    border-top: 1px solid #E9ECEF;
   padding: 28px 25px;
   background-color: #FFF;
}

footer.normal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F8F9FA;
}

.normal > .box-1 {
   padding: 16px 24px;
}

.normal > .box-2 {
    border-top: 1px solid #E9ECEF;
   padding: 28px 25px;
   background-color: #FFF;
}

@media  screen and (max-width: 1025px) {

    footer.summary {
        position: fixed;
    }
    

}

.min-h-empty-treament {
    height: calc(100vh - 450px);
}
@media  screen and (max-width: 1025px) {
    .min-h-empty-treament {
        height: calc(100vh - 350px);
    }
}